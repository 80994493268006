import {
  SET_USER,
  LOG_OUT,
  SET_DATA,
  SET_ID,
  SET_SUCCESS,
  SET_ERROR,
  UPDATE_OLD_RANK,
  SET_INFO,
} from "../constant/index";
import { auth, db } from "../../../../firebaseConfig/firebaseConfig";
import { history } from "../../../../history";

const handleUser = async (creds, user, dispatch) => {
  try {
    const isLoginRequest = creds ? true : false;
    const firebaseRes = await db
      .collection("UserData")
      .where("authId", "==", user.uid)
      .get();

    const matchedUsersData = [];

    firebaseRes.forEach((doc) => {
      matchedUsersData.push({
        ...doc.data(),
        docId: doc.id,
      });
    });

    const userData = matchedUsersData[0];
    // console.log("userData", userData);

    if (userData?.status === "approved") {
      if (
        creds &&
        userData?.tempPassword &&
        userData?.tempPassword === creds?.password
      ) {
        await auth.signOut();
        dispatch({ type: LOG_OUT });
        dispatch({
          type: SET_INFO,
          payload: "Please change your password to proceed next.",
        });
        return history.replace("/reset-password");
      } else {
        dispatch({ type: SET_USER, payload: user });
        dispatch({ type: SET_DATA, payload: userData });
        // dispatch({ type: SET_SUCCESS, payload: "Welcome Back" });
        dispatch(setId(userData?.docId));
        return history.replace("/");
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: "You are blocked by admin",
      });
      await auth.signOut();
      dispatch({ type: LOG_OUT });
      return;
    }
  } catch (error) {
    throw error.message;
  }
};

// Actions
export const creditionals = (data, setloading) => async (dispatch) => {
  try {
    if (data) {
      // console.log("creds", data);
      const user = await auth.signInWithEmailAndPassword(
        data?.email.toLowerCase(),
        data?.password
      );
      if (user) {
        await handleUser(data, user?.user, dispatch);
        setloading(false);
      } else {
        history.push("/login");
      }
    }
  } catch (error) {
    let message = error.message;
    dispatch({ type: SET_ERROR, payload: message });
  }
};

export const getCurrentUser = (setloader) => (dispatch) => {
  auth.onAuthStateChanged(
    async (user) => {
      // console.log("check");
      try {
        // console.log("user", user);
        if (user) {
          await handleUser(null, user, dispatch);
          setloader(false);
        } else {
          history.push("/login");
          setloader(false);
        }
      } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.message });
        setloader(false);
      }
    },
    (error) => {
      setloader(false);
      console.log("error", error.message);
    }
  );
};

export const setData = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_DATA, payload: data });
  } catch (error) {
    console.log("error setdata", error);
  } finally {
  }
};

export const setId = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_ID, payload: id });
  } catch (error) {
    console.log("error setdata", error);
  }
};

export const updateOldRank = (rankTitle, docId) => async (dispatch) => {
  try {
    await db.collection("UserData").doc(docId).update({
      oldRankTitle: rankTitle,
    });

    dispatch({
      type: UPDATE_OLD_RANK,
      payload: { oldRankTitle: rankTitle },
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const SendEmail = (email) => async (dispatch) => {
  try {
    await auth.sendPasswordResetEmail(email);
    dispatch({
      type: SET_SUCCESS,
      payload: "Email Sent, Check your Mail inbox.",
    });

    history.push("/login");
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.message });
    // console.log("error SendEmail", error.message);
  } finally {
  }
};

export const logout = () => async (dispatch) => {
  try {
    const testing = await auth.signOut();
    dispatch({ type: LOG_OUT });
    // console.log('Result logout', testing);
    dispatch({ type: SET_SUCCESS, payload: "Successfully Logout" });
    history.push("/login");
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.message });
    console.log("error logout", error);
  }
};

export const setError = (error) => (dispatch) => {
  try {
    dispatch({ type: SET_ERROR, payload: error });
  } catch (err) {
    dispatch({ type: SET_ERROR, payload: err });
  }
};

export const setSuccess = (success) => (dispatch) => {
  try {
    dispatch({ type: SET_SUCCESS, payload: success });
  } catch (err) {
    dispatch({ type: SET_SUCCESS, payload: success });
  }
};

export const updateUser = (data, id, setload) => async (dispatch, getState) => {
  // console.log('action', data);
  try {
    setload(true);
    db.collection("UserData")
      .doc(id)
      .update(data)
      .then(function () {
        dispatch({ type: SET_SUCCESS, payload: "Updated successfully!" });
        dispatch({ type: SET_DATA, payload: data });
        setload(false);
        // history.push('/')
      });
  } catch (error) {
    dispatch({ type: "SET_ERROR", payload: error });
    // console.log(error);
  }
};
