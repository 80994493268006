import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';

export default function AlertComponent(message) {
  const [state, setState] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (message.message !== "") {
      setState(true)
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_ERROR', payload: "" })
      }, 5000);
      return () => clearTimeout(timer);

    } else setState(false)
  }, [])

  return (
    <div>
      <Snackbar
        open={state}
        autoHideDuration={8000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {
          message !== "" ?
            <Alert
              variant="filled"
              severity="error">
              {message.message}
            </Alert>
            : null
        }

      </Snackbar>
    </div>
  );
}