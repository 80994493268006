import React from "react";
import logo from "../../assets/techloset.png";
import "../../assets/scss/components/app-loader.scss";
import Loader from "./Loader";
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100" style={{ textAlign: "center" }}>
        <img
          className="fallback-logo"
          width="180px"
          height="120px"
          src={logo}
          alt="logo"
        />
        <Loader />
      </div>
    );
  }
}

export default SpinnerComponent;
