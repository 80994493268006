export const FETCH_EVENTS = 'FETCH_EVENTS';
export const HANDLE_SIDEBAR = 'HANDLE_SIDEBAR';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_DRAG = 'UPDATE_DRAG';
export const EVENT_RESIZE = 'EVENT_RESIZE';
export const HANDLE_SELECTED_EVENT = 'HANDLE_SELECTED_EVENT';
export const ADD_EVENT = 'ADD_EVENT';
export const SET_ERROR = 'SET_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';
export const DELETE_EVENT = 'DELETE_EVENT';
