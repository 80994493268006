import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"

import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import { logout } from "../../../modules/authentication/redux/action"
import { useDispatch, useSelector } from "react-redux"


const NavbarUser = () => {
  // const [langDropdown, setlangDropdown] = useState(false)
  const dispatch = useDispatch()
  const { loggedIn } = useSelector(state => state.userReducer);
  const { profilephoto, fullName } = useSelector(state => state.userReducer.data)

  // const handleLangDropdown = () => {
  //   setlangDropdown(!langDropdown)
  // }

  const UserDropdown = props => {
    return (
      <DropdownMenu right>
        <DropdownItem
          tag="a"
          href="/login"
          onClick={e => {
            e.preventDefault()
            dispatch(logout())
          }}
        >
          <Icon.Power size={14} className="mr-50" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    )
  }

  return (
    <>
      {
        loggedIn ?
          <ul className="nav navbar-nav navbar-nav-user float-right">
            {/* <UncontrolledDropdown
              tag="li"
              className="dropdown-notification nav-item"
            >
              <DropdownToggle tag="a" className="nav-link nav-link-label">
                <Icon.Bell size={21} />
                <Badge pill color="primary" className="badge-up">
                  {" "}
              2{" "}
                </Badge>
              </DropdownToggle>
              <DropdownMenu tag="ul" right className="dropdown-menu-media">
                <li className="dropdown-menu-header">
                  <div className="dropdown-header mt-0">
                    <h3 className="text-white">5 New</h3>
                    <span className="notification-title">App Notifications</span>
                  </div>
                </li>
                <PerfectScrollbar
                  className="media-list overflow-hidden position-relative"
                  options={{
                    wheelPropagation: false
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Media className="d-flex align-items-start">
                      <Media left href="#">
                        <Icon.PlusSquare
                          className="font-medium-5 primary"
                          size={21}
                        />
                      </Media>
                      <Media body>
                        <Media heading className="primary media-heading" tag="h6">
                          You have new order!
                    </Media>
                        <p className="notification-text">
                          Are your going to meet me tonight?
                    </p>
                      </Media>
                      <small>
                        <time
                          className="media-meta"
                          dateTime="2015-06-11T18:29:20+08:00"
                        >
                          9 hours ago
                    </time>
                      </small>
                    </Media>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Media className="d-flex align-items-start">
                      <Media left href="#">
                        <Icon.DownloadCloud
                          className="font-medium-5 success"
                          size={21}
                        />
                      </Media>
                      <Media body>
                        <Media heading className="success media-heading" tag="h6">
                          99% Server load
                    </Media>
                        <p className="notification-text">
                          You got new order of goods?
                    </p>
                      </Media>
                      <small>
                        <time
                          className="media-meta"
                          dateTime="2015-06-11T18:29:20+08:00"
                        >
                          5 hours ago
                    </time>
                      </small>
                    </Media>
                  </div>
                </PerfectScrollbar>
                <li className="dropdown-menu-footer">
                  <DropdownItem tag="a" className="p-1 text-center">
                    <span className="align-middle">Read all notifications</span>
                  </DropdownItem>
                </li>
              </DropdownMenu>
            </UncontrolledDropdown> */}

            <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
              <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                <div className="user-nav d-sm-flex d-none">
                  <span className="user-name text-bold-600">
                    {fullName}
                  </span>
                  {/* <span className="user-status">Available</span> */}
                </div>
                <span data-tour="user">
                  <img
                    src={profilephoto}
                    className="round"
                    height="40"
                    width="40"
                    alt="avatar"
                  />
                </span>
              </DropdownToggle>

              <UserDropdown />
            </UncontrolledDropdown>
          </ul>
          : <div style={{ textAlign: 'right' }}>
            <a href="https://www.facebook.com/techloset" target="_blank" rel="noopener noreferrer">
              <Icon.Facebook size={22} className="text-primary brand-text mb-0" style={{ marginRight: '15px' }} />
            </a>
            <a href="https://www.linkedin.com/company/techloset/about/" target="_blank" rel="noopener noreferrer">
              <Icon.Linkedin size={22} className="text-primary brand-text mb-0" style={{ marginRight: '15px' }} />
            </a>
            <a href="https://www.youtube.com/channel/UCPC0RXZrvh50scjAzJC-IWg?view_as=subscriber" target="_blank" rel="noopener noreferrer">
              <Icon.Youtube size={22} className="text-primary brand-text mb-0" style={{ marginRight: '15px' }} />
            </a>
            <a href="https://twitter.com/techloset" target="_blank" rel="noopener noreferrer">
              <Icon.Twitter size={22} className="text-primary brand-text mb-0" />
            </a>
          </div>
      }
    </>
  )
}

export default NavbarUser;