import {
  FETCH_EVENTS,
  HANDLE_SIDEBAR,
  UPDATE_EVENT,
  UPDATE_DRAG,
  EVENT_RESIZE,
  ADD_EVENT,
  HANDLE_SELECTED_EVENT,
  DELETE_EVENT,
} from "../constants/constant";

const initialState = {
  events: [],
  sidebar: false,
  selectedEvent: null,
};

const calenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case ADD_EVENT:
      state.events.push(action.payload);
      return {
        ...state,
      };
    case DELETE_EVENT:
      let id = action.payload;
      return {
        ...state,
        events: state.events.filter((event) => event?.id !== id),
      };
    case UPDATE_EVENT:
      let updatedEvents = state.events.map((event) => {
        if (event?.id === action.payload.id) {
          return action.payload;
        }
        return event;
      });
      return {
        ...state,
        events: updatedEvents,
      };
    case UPDATE_DRAG:
      let eventToDrag = action.event,
        extractedEvent = state.events.map((event) => {
          if (event?.id === eventToDrag?.id) {
            return eventToDrag;
          }
          return event;
        });
      return {
        ...state,
        events: extractedEvent,
      };
    case EVENT_RESIZE:
      let eventToResize = action.event,
        resizeEvent = state.events.map((event) => {
          if (event?.id === eventToResize?.id) {
            return eventToResize;
          }
          return event;
        });
      return {
        ...state,
        events: resizeEvent,
      };
    case HANDLE_SIDEBAR:
      return {
        ...state,
        sidebar: action.status,
      };
    case HANDLE_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.event,
      };
    default:
      return state;
  }
};

export default calenderReducer;
