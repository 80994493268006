import {
  FETCH_LEAVES,
  HANDLE_SIDEBAR,
  UPDATE_LEAVE,
  UPDATE_DRAG,
  LEAVE_RESIZE,
  ADD_LEAVE,
  HANDLE_SELECTED_LEAVE,
  DELETE_LEAVE,
} from "../constants/leavesConstant";

const initialState = {
  leaves: [],
  sidebar: false,
  selectedEvent: null,
};

const leavesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEAVES:
      return {
        ...state,
        leaves: action.payload,
      };
    case ADD_LEAVE:
      state.leaves.push(action.payload);
      return {
        ...state,
      };
    case DELETE_LEAVE:
      let id = action.payload;
      return {
        ...state,
        leaves: state.leaves.filter((event) => event.id !== id),
      };
    case UPDATE_LEAVE:
      let updatedleaves = state.leaves.map((event) => {
        if (event.id === action.payload.id) {
          return action.payload;
        }
        return event;
      });
      return {
        ...state,
        leaves: updatedleaves,
      };
    case UPDATE_DRAG:
      let eventToDrag = action.event,
        extractedEvent = state.leaves.map((event) => {
          if (event.id === eventToDrag.id) {
            return eventToDrag;
          }
          return event;
        });
      return {
        ...state,
        leaves: extractedEvent,
      };
    case LEAVE_RESIZE:
      let eventToResize = action.event,
        resizeEvent = state.leaves.map((event) => {
          if (event.id === eventToResize.id) {
            return eventToResize;
          }
          return event;
        });
      return {
        ...state,
        leaves: resizeEvent,
      };
    case HANDLE_SIDEBAR:
      return {
        ...state,
        sidebar: action.status,
      };
    case HANDLE_SELECTED_LEAVE:
      return {
        ...state,
        selectedEvent: action.event,
      };
    default:
      return state;
  }
};

export default leavesReducer;
