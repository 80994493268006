import { GET_ADJUSTED_RANK, GET_ALL_RANKS } from "./constants";

let initialState = {
    adjustedData: [],
    allRanks:[]
}

const ranksReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADJUSTED_RANK:
            return {
                ...state,
                adjustedData: action.payload
            }
        case GET_ALL_RANKS:
            return {
                ...state,
                allRanks: action.payload
            }

        default:
            return state;
    }
}

export default ranksReducer;