export const SET_USER = "SET_USER";
export const LOG_OUT = "LOG_OUT";
export const SET_DATA = "SET_DATA";
export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const SEND_EMAIL = "SEND_EMAIL";
export const SET_ERROR = "SET_ERROR";
export const UPDATE = "UPDATE";
export const SET_ID = "SET_ID";
export const SET_SUCCESS = "SET_SUCCESS";
export const UPDATE_OLD_RANK = "UPDATE_OLD_RANK";
export const SET_INFO = "SET_INFO";
