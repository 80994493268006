import { combineReducers } from "redux";
import customizer from "./customizer/";
import userReducer from "../../modules/authentication/redux/reducer/index";
import calenderReducer from "../../modules/events/redux/calendarReducer/index";
import letterReducer from "../../modules/experienceLetter/redux/letterReducer/letterReducer";
import paystubReducer from "../../modules/paystub/redux/reducers/paystubReducer";
import leavesReducer from "../../modules/leaves/redux/calendarReducer/leavesReducer";
import ranksReducer from "../../modules/promotions/redux/reducer";
import bankReducer from "../../modules/main/redux/reducer/bankReducer";
import letters from "../../modules/letters/redux/reducers/letterReducer";
const rootReducer = combineReducers({
  userReducer,
  customizer,
  calenderReducer,
  letterReducer,
  paystubReducer,
  leavesReducer,
  ranksReducer,
  bankReducer,
  letters,
});

export default rootReducer;
