import { GET_ALL_BANKS } from "../constant/bankConstant";

const initialState = {
  allBanks: [],
};

const bankReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BANKS:
      return {
        ...state,
        allBanks: action.payload,
      };
    default:
      return state;
  }
};
export default bankReducer;
