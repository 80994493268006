import {
  SET_USER,
  LOG_OUT,
  SET_DATA,
  SIGN_IN,
  SIGN_UP,
  SEND_EMAIL,
  SET_ERROR,
  UPDATE,
  SET_ID,
  SET_SUCCESS,
  UPDATE_OLD_RANK,
  SET_INFO,
} from "../constant";

const initialState = {
  id: "",
  user: {},
  loggedIn: false,
  data: {},
  errorMessage: "",
  successMessage: "",
  infoMessage: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
      };
    }
    case SIGN_IN: {
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
      };
    }
    case LOG_OUT: {
      return {
        ...state,
        loggedIn: false,
        user: {},
        id: "",
      };
    }
    case SIGN_UP: {
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
      };
    }
    case SET_ID: {
      return {
        ...state,
        id: action.payload,
      };
    }
    case SET_DATA: {
      return {
        ...state,
        data: action.payload,
        loggedIn: true,
      };
    }
    case UPDATE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case UPDATE_OLD_RANK: {
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    }
    case SEND_EMAIL: {
      return {
        ...state,
        data: {},
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }

    case SET_SUCCESS: {
      return {
        ...state,
        successMessage: action.payload,
      };
    }
    case SET_INFO: {
      return {
        ...state,
        infoMessage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
