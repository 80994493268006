import {
  REQUEST_LETTER,
  FETCH_REQUESTS,
  UPDATE_REQUEST,
  JOB_TYPE,
  REMOVE,
} from '../constants/constants';

const initialState = {
  request: {},
  alreadyExist: false,
  letterType: '',
};

const letterReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LETTER:
      return {
        ...state,
        request: action.payload,
        alreadyExist: true,
      };
    case JOB_TYPE:
      return {
        ...state,
        letterType: action.payload,
      };
    case FETCH_REQUESTS:
      return {
        ...state,
        request: action.payload,
        reason: action.payload.reason,
        alreadyExist: true,
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        reason: action.payload.reason,
        alreadyExist: true,
      };
    case REMOVE:
      return {
        request: {},
        alreadyExist: false,
      };
    default:
      return state;
  }
};

export default letterReducer;
